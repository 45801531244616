import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () =>
            import('../views/Home.vue')
    },
    {
        path: '/article',
        name: 'Article',
        component: () =>
            import('../views/articleList/article.vue'),
        children: [{
            path: "xygk",
            name: "Xygk",
            redirect: { name: "Yzjy" },
            component: () =>
                import('@/views/articleList/schoolsurvey/xygk.vue'),
            children: [{
                path: "yzjy/:obj",
                name: "Yzjy",
                component: () =>
                    import('@/views/articleList/schoolsurvey/yzjy.vue'),
            }]
        },
        {
            path: "szdw",
            name: "Szdw",
            component: () =>
                import('@/views/articleList/tchar_szdw/szdw.vue'),
            children: [{
                path: "zrjs/:obj",
                name: "Zrjs",
                component: () =>
                    import('@/views/articleList/tchar_szdw/zrjs.vue')
            }, {
                path: "szkg/:obj",
                name: "Szkg",
                component: () =>
                    import('@/views/articleList//tchar_szdw/Szkg.vue')
            }]
        },
        {
            path: "leader/:obj",
            name: "Leader",
            component: () =>
                import("@/views/articleList/schoolsurvey/leader.vue")
        },
        {
            path: "wzlist/:obj",
            name: "Wzlist",
            component: () =>
                import("@/views/articleList/moduleClassification/wz_list.vue")
        },
        {
            path: "xyxw/:obj",
            name: "Xyxw",
            component: () =>
                import("@/views/articleList/moduleClassification/xyxw.vue")
        },
        {
            path: "kyzs/:obj",
            name: "Kyzs",
            component: () =>
                import("@/views/articleList/moduleClassification/kyzs.vue")
        },
        {
            path: "gyjg/:obj",
            name: "Gyjg",
            component: () =>
                import("@/views/articleList/moduleClassification/gyjg.vue")
        },
        {
            path: "article_xq/:obj",
            name: "Article_xq",
            component: () =>
                import("@/views/articleList/schoolsurvey/article_xq.vue")
        },
        {
            path: "article_xq_time/:obj",
            name: "Article_xq_time",
            component: () =>
                import("@/views/articleList/schoolsurvey/article_xq_time.vue")
        },
        {
            path: "lxwm/:obj",
            name: "Lxwm",
            component: () =>
                import("@/views/articleList/schoolsurvey/lxwm.vue")
        },
        {
            path: "yjzx/:obj",
            name: "Yjzx",
            component: () =>
                import("@/views/researchCenter/researchCenter.vue")
        },
        {
            path: "training2/:obj",
            name: "Training2",
            redirect: { name: "Training" },
        },
        {
            path: "training/:obj",
            name: "Training",
            component: () =>
                import("@/views/articleList/serviceTraining/training.vue")
        },
        {
            path: "search/:obj",
            name: "Search",
            component: () =>
                import("@/views/articleList/search/ind.vue")
        },
        {
            path: "bgxm/:obj",
            name: "Bgxm",
            component: () =>
                import("@/views/articleList/serviceTraining/biaogan.vue")
        },
        {
            path: "studentgy/:obj",
            name: "Studentgy",
            component: () =>
                import("@/views/articleList/serviceTraining/studentgy.vue")
        },
        {
            path: "onlineRegistration/:obj",
            name: "OnlineRegistration",
            component: () =>
                import("@/views/articleList/serviceTraining/onlineRegistration.vue")
        },
        {
            path: "zsrz/:obj",
            name: "Zsrz",
            component: () =>
                import("@/views/articleList/serviceTraining/queryCertificate.vue")
        },
        ]
    },
    {
        path: '/en',
        name: 'En',
        component: () =>
            import('@/views/english/home.vue')
    },
    {
        // path: '/enarticle/:obj',
        path: '/enarticle',
        name: 'Enarticle',
        component: () =>
            import('@/views/english/enarticle/enarticle.vue'),
        children: [{
            path: 'enyzjy/:id/:tid/:level?',
            name: 'Enyzjy',
            component: () =>
                import('@/views/english/enarticle/enTwo/enyzjy.vue')
        },
        {
            path: 'enarticlexq/:articleid/:id/:tid/:level?',
            name: 'Enarticlexq',
            component: () =>
                import('@/views/english/enarticle/enTwo/enarticlexq.vue')
        },
        {
            path: 'enarticlelist/:id/:tid/:level?',
            name: 'Enarticlelist',
            meta: {
                keepAlive: true //该页面需要被缓存
            },
            component: () =>
                import('@/views/english/enarticle/enTwo/enarticlelist.vue')
        },
        {
            path: 'enleader/:id/:tid/:level?',
            name: 'Enleader',
            component: () =>
                import('@/views/english/enarticle/enTwo/enleader.vue')
        },
        {
            path: 'envideo/:id/:tid/:level?',
            name: 'Envideo',
            component: () =>
                import('@/views/english/enarticle/enTwo/envideo.vue')
        },
        {
            path: 'ensearch/:id/:tid/:level?',
            name: 'Ensearch',
            meta: {
                keepAlive: true //该页面需要被缓存
            },
            component: () =>
                import('@/views/english/enarticle/enTwo/ensearch.vue')
        },
        {
            path: 'enzrjs/:id/:tid/:level?',
            name: 'Enzrjs',
            meta: {
                // keepAlive:true //该页面需要被缓存
            },
            component: () =>
                import('@/views/english/enarticle/enTwo/enzrjs.vue')
        },
        ]
    },
    {
        path: '/moelab',
        name: 'Moelab',
        component: () =>
            import('@/views/laboratory/home.vue')
    },
    {
        path: '/moearticle',
        name: 'Moearticle',
        component: () =>
            import('@/views/laboratory/labarticle/labarticle.vue'),
        children: [{
            path: 'moearticlelist/:id/:tid/:level?/:isen?',
            name: 'Moearticlelist',
            meta: {
                keepAlive: true //该页面需要被缓存
            },
            component: () =>
                import('@/views/english/enarticle/enTwo/enarticlelist.vue')
        },
        {
            path: 'moearticlexq/:articleid/:id/:tid/:level?/:isen?',
            name: 'Moearticlexq',
            component: () =>
                import('@/views/english/enarticle/enTwo/enarticlexq.vue')
        },
        {
            path: 'moesearch/:id/:tid/:level?/:isen?',
            name: 'Moesearch',
            meta: {
                keepAlive: true //该页面需要被缓存
            },
            component: () =>
                import('@/views/english/enarticle/enTwo/ensearch.vue')
        },
        {
            path: 'rcdw/:id/:tid/:level?/:isen?',
            name: 'Rcdwlist',
            meta: {
                // keepAlive:true //该页面需要被缓存
            },
            component: () =>
                import('@/views/laboratory/labarticle/rcdwlist.vue')
        },
        ]
    },
    {
        path: '/edp',
        name: 'edp',
        component: () => import('@/views/edp/home/index.vue'),
    },
    {
        path: '/edps',
        name: 'edps',
        component: () => import('@/layout/index.vue'),
        children: [
            {
                path: 'news/:detailsId?/:navId?',
                name: 'news',
                component: () => import('@/views/edp/news/index.vue'),
                meta: { title: '资讯' }
            },
            {
                path: 'hot/:detailsId?/:navId?',
                name: 'hot',
                component: () => import('@/views/edp/hotProject/index.vue'),
                meta: { title: '项目' }
            },
            {
                path: 'renowned/:detailsId?/:navId?',
                name: 'renowned',
                component: () => import('@/views/edp/renowned/index.vue'),
                meta: { title: '学者' }
            },
            {
                path: 'user/:detailsId?/:navId?',
                name: 'user',
                component: () => import('@/views/edp/user/index.vue'),
                meta: { title: '人员详情1' }
            },
            {
                path: 'detile/:detailsId?/:navId?',
                name: 'detile',
                component: () => import('@/views/edp/detile/index.vue'),
                meta: { title: '人员详情2', isTitle: true }
            },
            {
                path: 'query/:detailsId?/:navId?',
                name: 'query',
                component: () => import('@/views/edp/query/index.vue'),
                meta: { title: '证书查询' }
            },
            {
                path: 'contactus/:detailsId?',
                name: 'contactus',
                component: () => import('@/views/edp/contactUs/index.vue'),
                meta: { title: '在线报名' }
            },
            {
                path: 'videolist/:detailsId?',
                name: 'videolist',
                component: () => import('@/views/edp/videoList/index.vue'),
                meta: { title: '光影国科' }
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/edp/notFont/404.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/notfound'
    }
]

const router = createRouter({
    history: createWebHistory(),
    // history: createWebHashHistory(),
    routes,
    scrollBehavior: (to, from) => {
        if (to.path.search('/edps') >= 0 && from.path.search('/edps') != 0) {
            return { top: 0 }
        }
    }
})

router.afterEach((to, from) => {
    if (to.fullPath.includes('/edp') || to.fullPath.includes('/edps')) {
        document.title = '中国科学院大学经济与管理学院EDP中心';
    } else {
        document.title = '中国科学院大学 | 经济与管理学院';
    }

});

export default router