import SvgIcon from "@/components/svg-icon.vue"; // svg component

export default function (app) {
  const req = require.context("./icons", false, /\.svg$/);
  app.component("svg-icon", SvgIcon);
  function requireAll(requireContext) {
    return requireContext.keys().map(requireContext);
  }

  requireAll(req);
}
