import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import createSvg from "./icons";
// import '@/style/iview.css'
import "@/fonts/font.css";
import "@/style/media.less";
import "@/utils/flexbo.js";
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import ViewUIPlus from 'view-ui-plus'


const app = createApp(App);
// 自定义警告处理函数，将警告输出到控制台或者忽略它们
app.config.warnHandler = (warn) => {};
app.config.errorHandler = (error) => {};
app.config.globalProperties.$img = process.env.NODE_ENV === 'development' ? "https://zk.itxiangtian.com" : '';
createSvg(app);
// app.component('svg-icon', svgIcon)
app.use(store).use(router).use(ElementPlus).use(ViewUIPlus).mount("#app");
