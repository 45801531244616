<template>
  <router-view />
</template>

<script setup>
import miiter from '@/utils/miitbus.js'
import { onMounted } from 'vue'
import { getHttp } from '@/api/API.js'
getHttp("/api/isBackWhite", '').then(res => {
  if (res.data.data.value == '1') {
    document.documentElement.id = 'filters'
  }
})

onMounted(() => {
  window.addEventListener('scroll', () => {
    if (document.documentElement.scrollTop >= 800) {
      miiter.emit('mittFn')
    } else if (document.documentElement.scrollTop < 790) {
      miiter.emit('endmittFn')
    }
  })
})
window.sessionStorage.setItem("flag", 1)
</script>

<style lang="less">
#filters {
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #355f9f30;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: #1b5bbc97;
  border-radius: 20px;
}

@media screen and (max-width:750px) {
  ::-webkit-scrollbar {
    width: 0px;
  }
}

</style>
