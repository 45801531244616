import http from '@/utils/requset.js'

// 通用get请求
export const getHttp = (url,res) => {
  return http({
    url:url,
    method:'get',
    params:res,
  })
}

// 通用post请求
export const postHttp = (url,res) => {
  return http({
    url:url,
    method:'post',
    data:res,
  })
}

// 获取导航栏
export const getNavlist = (res) => {
  return http({
    url:`/api/nav`,
    method:'get',
    params:res
  })
}
// 根据id获取对应的侧边导航栏数据
export const getNavlist_id = (res) => {
  return http({
    url:`/api/nav_sub`,
    method:'get',
    params:res
  })
}

// 根据id获取对应的四级导航栏数据
export const getNavlist_id_f = (res) => {
  return http({
    url:`/api/nav_sub1`,
    method:'get',
    params:res
  })
}

// 根据id获取对应的文章详情数据
export const get_aeticle_xq = (res) => {
  return http({
    url:`/api/article/view`,
    method:'post',
    data:res
  })
}


// 获取home首页列表数据
export const get_home_list = (res) => {
  return http({
    url:`/api/index`,
    method:'get',
    params:res
  })
}
// 根据home主页的type_id获取对应的文章列表
export const get_article_list = (res) => {
  return http({
    url:`/api/article`,
    method:'get',
    params:res
  })
}
// 获取banner导航列表
export const getBannernav_list = (res) => {
  return http({
    url:"/api/cate_sub",
    method:"get",
    params:res
  })
}


// 获取移动端导航栏数据
export const getPhonenav_data = (res) => {
  return http({
    url:"/api/nav_all",
    method:"get",
    params:res
  })
}

// 获取移动端导航栏数据
export const getSetup = (res) => {
  return http({
    url:"/api/home_frame",
    method:"get",
    params:res
  })
}



// 师资概况数据
export const getSzkglist = (res) => {
  return http({
    url:"/api/teachingStaff",
    method:"get",
    params:res
  })
}

// 头部右侧导航栏数据
export const getRightnavlist = (res) => {
  return http({
    url:"/api/fu_nav",
    method:"get",
    params:res
  })
}